<template>
  <div class="legal container">
    <h1 class="mt-3">Conditions générales d'utilisation</h1>
    <p class="mt-3">
      Le site internet tournai-gite.be (ci après le "site") est
      une initiative de : <br>
      Darras Bernard SPRL<br>
      Chaussée de Lille 499, 7501 Orcq<br>
      N d'entreprise (BCE/TVA) : 0465.170.131
      Joignale à l'e-mail et au téléphone présents dans la page contact)
      Non-assujeti à la TVA.
    </p>
    <p class="mt-3">
      Les présentes conditions générales d'utilisation (ci après les "GCU")
      s'appliquent à toute visite ou utilisation du site et de ses informations
      par un internaute (ci-après "visiteur").
      <br>
      En visitant ou en utilisant le site, le visiteur reconnait avoir pris
      connaissance des présentes CGU et accepte expressément les droits et
      obligations qui y sont mentionnées.
      <br>
      Ce site est normalement accessible à tout moment aux utilisateurs.
      Une interruption pour raison de maintenance reste malgré tout possible.
      Sauf accord préalable, les droits de propriété intellectuelle relatifs au présent
      site web ainsi qu’à son contenu appartiennent exclusivement à leurs propriétaires.
      <br>
      Nous prenons les mesures raisonnables et nécessaires pour assurer le bon fonctionnement,
      la sécurité et l'accessibilité de notre site. Toutefois, nous ne pouvons pas offrir
      de garantie d'opérabilité absolue et il faut dès lors considérer nos actions comme
      étant couvertes par une obligation de moyens.
      Toute utilisation du site se fait toujours aux propres risques du visiteur. Ainsi,
      nous ne sommes pas responsables des ommages pouvant résulter de possibles dsyfonctionnements
      , interruptions, défauts ou encore d'éléments nuisibles présents sur le site.
    </p>
    <p>
      Darras Bernard SPRL ne peut pas offrir de garantie absolue concernant la qualité de
      l'information présente sur le site. Il est donc possible que cette information ne soit
      pas toujours complète, exacte, suffisament précise ou à jour. Par conséquent, Darras Bernard
      SPRL ne pourra pas être tenu responsable des dommages, directs ou indirects, que le visiteur
      subirait de par l'information présente sur le site.
    </p>
    <p>
      En aucun cas, Bernard Darras SPRL n’engagera sa responsabilité pour des dommages matériels ou moraux,
      directs ou indirects, causés par la consultation du site et des ressources disponibles par l’Internaute.
      L’internaute est seul responsable de sa navigation, et ce y compris l’aspect sécurité (surf sécurisé, antivirus,…).
    </p>
    <p>
      Si certains contenus du site sont en violation avec la loi ou les droits des tiers,
      ou sont contraires à la morale, nous vous demandons de nous en informer le plus
      rapidement possible par mail afin que nous puissions prendre des mesures appropriées.
    </p>
    <p>
      Le site contient un certain nombre de liens hypertextes vers d’autres sites.
      Cependant, nous n’avons pas la possibilité de vérifier le contenu des sites ainsi visités.
      En conséquence, aucune responsabilité ne sera assumée concernant ces liens.
    </p>
    <p>
      Toute copie, adaptation, traduction, arrangement, communication au public,
      location et autre exploitation de tout ou partie de ce site sous quelque forme
      que ce soit et par quelque moyen que ce soit est strictement interdit.
    </p>
    <p>
      Tout litige ne ressort que de la compétence exclusive des cours, tribunaux et justice de paix de l’arrondissement de Tournai.
      Le droit belge est seul applicable, dans une procédure en français.
    </p>
    <h1>Données personnelles et Cookies</h1>
    <p>
      Aucune donnée utilisateur n’est collectée à travers ce site web.
<!--
      Quand c’est le cas, l’utilisateur fournit ces informations en connaissance de cause
      et peut effectuer son droit d’accès, de rectification et d’opposition aux données personnelles
      le concernant simplement en envoyant un e-mail via la page de contact.
-->
      Aucune information personnelle n’est publiée à l’insu de l’utilisateur du site, ni échangée, transférée,
      cédée ou vendue sur un support quelconque à des tiers.
      <br><br>
      La navigation sur le site est susceptible de provoquer l’installation de cookie(s) sur l’ordinateur de l’utilisateur.
      Un cookie est un fichier de petite taille, qui ne permet pas l’identification de l’utilisateur,
      mais qui enregistre des informations relatives à la navigation d’un ordinateur sur un site.
      Les données ainsi obtenues visent à faciliter la navigation ultérieure sur le site, et ont
      également vocation à permettre diverses mesures de fréquentation (via des outils tels que Google Analytics).
      Le refus d’installation d’un cookie peut entraîner l’impossibilité d’accéder à certains services. L’utilisateur peut
      toutefois configurer son ordinateur de manière à empêcher l’enregistrement et l’exploitation de ses données personnelles,
      en téléchargeant et installant le plugin disponible sous http://tools.google.com/dlpage/gaoptout.
    </p>
    <h1>Hébergement du site</h1>
    <p>
      Site Internet hébergé par Ovh.<br>
      SAS au capital de 10 069 020 €<br>
      RCS Lille Métropole 424 761 419 00045<br>
      Code APE 2620Z<br>
      N° TVA : FR 22 424 761 419<br>
      Siège social : 2 rue Kellermann – 59100 Roubaix – FranceDirecteur de la publication : Octave KLABAPrésident : Henryk KLABADirecteur général : Miroslaw KLABADirecteur général délégué : Octave KLABADirecteur général délégué : Halina KLABA
    </p>
  </div>
</template>
